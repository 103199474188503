import React from 'react';

const Editdata = () => {
    return (
        <div>
            Edit data
        </div>
    );
};

export default Editdata;