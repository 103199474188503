import React from 'react';
import auth from './img/auth.jpg';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate } from 'react-router-dom';
import UserDashbord from './UserDashbord';

const Auth = () => {

    const { user, loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();

    console.log(user);
    if (isLoading) {
        return <div>Loading ...</div>;
    }


    const style = {
        position: 'relative',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${auth})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'font-nunito',
    };
    const containerStyle = {
        width: isAuthenticated ? '100%' : '400px', // Set width to 100% if authenticated, else 400px
        // margin: 'auto', // Center the container
    };

    return (
        <div style={style}>
            <div className='w-[400px] h-auto text-white mx-auto ' style={containerStyle}>



                {
                    isAuthenticated ? (
                        // <div>
                        //     <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        //         Log Out
                        //     </button>
                        //     <div>
                        //         <img src={user.picture} alt={user.name} />
                        //         <h2>{user.name}</h2>
                        //         <p>{user.email}</p>
                        //     </div>
                        // </div>
                        <UserDashbord user={user} />

                    ) : (
                        <div>
                            <h1 className='text-5xl font-bold mt-20'>Login As</h1>
                            <div className='mt-5 flex flex-col items-center text-center justify-center gap-3'>

                                <button onClick={() => loginWithRedirect()} className='w-[300px] bg-blue-500 rounded p-2'>
                                    User
                                </button>
                                <Link to='/adminlogin'>
                                    <button className='w-[300px] bg-blue-500 rounded p-2'>Admin</button>
                                </Link>
                            </div>
                        </div>

                    )
                }


            </div>
        </div>

    );
};

export default Auth;
