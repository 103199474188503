import React from 'react';
import Headline from '../Otherscomponent/Headline';
import Allcourses from '../Otherscomponent/Allcourses';
import { useNavigate } from 'react-router-dom';

const Courses = () => {
    const navigate = useNavigate();
    const handleChange = (name, img) => {
        navigate(`/language/${name}`, { state: { img } });
    };

    return (
        <div id='courses' className='container bg-white rounded-sm py-6 mt-[150px]'>
            <Headline className='w-full' parent="Start" child="Learning" short="Course" showSeemore={false}></Headline>
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-8 gap-3 mt-4">
                {Allcourses.map((tutorial) => (
                    <a
                        key={tutorial.id}
                        onClick={() => handleChange(tutorial.title, tutorial.img)}
                        className='shadow-sm rounded transition ease-in-out delay-50 hover:-translate-y-2 hover:scale-105 duration-300'
                    >
                        <div className='w-full h-36 rounded bg-slate-900 hover:bg-slate-800 flex flex-col items-center justify-center'>
                            <div>
                                <img className='w-[70px] h-[70px]' src={tutorial.img} alt="" />
                            </div>
                            <div className='text-white mt-1 font-verdina text-sm'>
                                {tutorial.title}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Courses;
