import React from 'react';

const Deletelanguage = () => {
    return (
        <div>
            delete

        </div>
    );
};

export default Deletelanguage;
