import React from 'react';

const Editlanguage = () => {
    return (
        <div>
            edit

        </div>
    );
};

export default Editlanguage;
