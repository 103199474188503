import library from '../../img/library.png';

const SixUniteObject = [

    {
        id: 1,
        title: 'Introduction',
        icon: library,
        links: [
            { to: '#', text: 'What is HTML?' },
            { to: '#', text: ' What is B' },
            { to: '#', text: 'What is C' },
            { to: '#', text: ' What is D' },

        ],
    },
    {
        id: 2,
        title: 'Description',
        icon: library,
        links: [
            { to: '#', text: 'What is  ' },
            { to: '#', text: ' What is B' },
            { to: '#', text: 'What is C' },
            { to: '#', text: ' What is D' },

        ],
    },
    {
        id: 3,
        title: 'Description Child',
        icon: library,
        links: [
            { to: '#', text: 'What is  ' },
            { to: '#', text: ' What is B' },
            { to: '#', text: 'What is C' },
            { to: '#', text: ' What is D' },

        ],
    },
]

export default SixUniteObject;