import React from 'react';
import Headline from '../../Otherscomponent/Headline';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import array from './img/array.png';
import stack from './img/stack.png';
import queue from './img/queue.png';
import linkedlist from './img/linkedlist.png';
import graph from './img/graph.png';
import tree from './img/tree.png';

const Dsa = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div id='dsa' className='container mt-16 bg-white rounded-sm py-6'>
            <Headline parent='DSA' child='Courses' short='Data Structure' showSeemore={false} />
            <Carousel responsive={responsive} className='mt-4' containerClass="carousel-container">
                <img src={array} alt="Array" className="carousel-img px-2" />
                <img src={stack} alt="Stack" className="carousel-img px-2" />
                <img src={queue} alt="Queue" className="carousel-img px-2" />
                <img src={linkedlist} alt="Linked List" className="carousel-img px-2" />
                <img src={graph} alt="Graph" className="carousel-img px-2" />
                <img src={tree} alt="Tree" className="carousel-img px-2" />
            </Carousel>
        </div>
    );
};

export default Dsa;
