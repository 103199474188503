import React from 'react';
import mainlogo from './img/mainlogo.png';
import playstore from './img/playstore.png';
import appstore from './img/appstore.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faCode, faEnvelope, faGears, faHeadset, faLayerGroup, faMap, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
// import bgdesign from './img/bgdesign.png';

const Footer = () => {
    // const divStyle = {
    //     width: '100%',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     backgroundImage: `url(${bgdesign})`,
    // };

    return (
        <div>
            {/* <div className='w-full h-[240px] bg-cover bg-center' style={divStyle}></div> */}
            <div className='bg-slate-950 w-full'>
                <div className='container mx-auto py-6 lg:py-10'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
                        <div className='flex flex-col items-center lg:items-start text-center lg:text-left'>
                            <img className='w-[150px] py-2' src={mainlogo} alt="Main Logo" />
                            <p className='font-nunito text-sm text-slate-200 py-2'>
                                CSE Hacks is an online platform where you can learn all about Computer Engineering and Science
                            </p>
                            <div className='flex space-x-2 mt-2'>
                                <img className='w-[140px]' src={playstore} alt="Play Store" />
                                <img className='w-[140px]' src={appstore} alt="App Store" />
                            </div>
                        </div>
                        <div className='text-slate-200'>
                            <p className='font-bold hover:text-lime-500 py-4'>About</p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faPeopleGroup} /> Team
                            </p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faHeadset} /> Support
                            </p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faBriefcase} /> Career
                            </p>
                        </div>
                        <div className='text-slate-200'>
                            <p className='font-bold hover:text-lime-500 py-4'>Learn</p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faCode} /> Programming Language
                            </p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faLayerGroup} /> Data Structure
                            </p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faGears} /> Online Compiler
                            </p>
                        </div>
                        <div className='text-slate-200'>
                            <p className='font-bold hover:text-lime-500 py-4'>Contact</p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faMap} /> Rajkot, Gujarat, India
                            </p>
                            <p className='hover:text-lime-500 font-serif mt-2'>
                                <FontAwesomeIcon className='mr-2' icon={faEnvelope} /> csehacks@gmail.com
                            </p>
                            <div className='flex space-x-3 mt-3'>
                                <FontAwesomeIcon className='hover:text-blue-500' icon={faGithub} size="2x" />
                                <FontAwesomeIcon className='hover:text-blue-500' icon={faLinkedin} size="2x" />
                                <FontAwesomeIcon className='hover:text-blue-500' icon={faFacebook} size="2x" />
                                <FontAwesomeIcon className='hover:text-blue-500' icon={faTwitter} size="2x" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
