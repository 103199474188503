import { faBookOpen, faBrain, faCode, faFilePdf, faNewspaper, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = ({ headline, description, image, shortimg, img1, img2, showImgDiv, showHeadButton, showInterviewDiv }) => {
    return (
        <div className='container flex flex-col md:flex-row md:w-full md:h-[450px]'>
            <div className='w-full md:w-1/2 headline-div md:mt-[80px]'>
                <span className='absolute ms-[43%] mt-[-5px]'><img className='w-16' src={shortimg} alt="" /></span>

                <h1 className='text-5xl ms-2 font-source-sans-3'>{headline}</h1>
                {showHeadButton &&
                    <div className='flex container mt-5 font-nutino text-slate-600'>
                        <div className='me-3 hover:-translate-y-1 hover:scale-110 transition duration-100 hover:bg-green-700 rounded bg-green-600 px-2 py-1 text-gray-200'><FontAwesomeIcon className='pe-1' icon={faBookOpen} />Free Course</div>
                        <div className='me-3 hover:-translate-y-1 hover:scale-110 transition duration-100 hover:bg-green-700 rounded bg-green-600 px-2 py-1 text-gray-200'><FontAwesomeIcon className='pe-1' icon={faFilePdf} />PDF</div>
                        <div className='me-3 hover:-translate-y-1 hover:scale-110 transition duration-100 hover:bg-green-700 rounded bg-green-600 px-2 py-1 text-gray-200'><FontAwesomeIcon className='pe-1' icon={faNewspaper} />Article</div>
                        <div className='me-3 hover:-translate-y-1 hover:scale-110 transition duration-100 hover:bg-green-700 rounded bg-green-600 px-2 py-1 text-gray-200   '><FontAwesomeIcon className='pe-2  ' icon={faCode} />Online IDE</div>

                    </div>}

                {showInterviewDiv &&
                    <div className="  container  text-start items-start font-nunito  mt-4">

                        <div className='grid grid-cols-2 text-start mt-3 gap-2'>
                            <Link to='/allcourse' className='w-full  flex items-center text-center px-2 font-bold border-2 rounded py-2  border-purple-400 hover:border-3 hover:border-white duration-100 hover:rounded-full text-white h-auto'> <FontAwesomeIcon icon={faBookOpen} className=' me-2 bg-white p-2 rounded-full  coding-icon34 text-blue-500' size='1xl' /> Assessing Proficiencies</Link>
                            <Link to='/practice' className='w-full flex items-center text-center px-2 font-bold border-2 rounded py-2  border-purple-400 hover:border-3 hover:border-white duration-100 hover:rounded-full text-white h-auto'> <FontAwesomeIcon icon={faBrain} className=' me-2 bg-white p-2 rounded-full  coding-icon34 text-red-500' size='1xl' /> Practice</Link>
                            <Link to='/interview' className='w-full  flex items-center text-center px-2 font-bold border-2 rounded py-2  border-purple-400 hover:border-3 hover:border-white duration-100 hover:rounded-full text-white h-auto'> <FontAwesomeIcon icon={faPeopleArrows} className=' me-2 bg-white p-2 rounded-full  coding-icon34 text-green-500' size='1xl' /> Interview Preparation</Link>
                            <Link to='/onlineide' className='w-full  flex items-center text-center px-2 font-bold border-2 rounded py-2  border-purple-400 hover:border-3 hover:border-white duration-100 hover:rounded-full text-white h-auto'> <FontAwesomeIcon icon={faCode} className=' me-2 bg-white p-2 rounded-full  coding-icon34 text-purple-500' size='1xl' /> Coding Practice</Link>
                        </div>


                    </div>
                }

                {/* <hr className='mt-2' /> */}
                <p className='mt-4 mx-3 font-nunito text-justify'>{description}</p>
                {showImgDiv &&
                    <div className='flex w-full gap-3 container mt-4'>
                        <div><img className='w-[250px] hover:scale-110 delay-50 duration-300 rounded border' src={img1} alt="" /></div>
                        <div><img className='w-[250px] hover:scale-110 delay-50 duration-300 rounded border' src={img2} alt="" /></div>
                    </div>}

            </div>

            <div className='w-full md:w-1/2 flex justify-center items-center'>
                <img className='w-[450px] mt-0 pt-0' src={image} alt="" />
            </div>
        </div>
    );
};

export default HomePage;
