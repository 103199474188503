import React, { useEffect, useState } from 'react';
import popup from './popup-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Popup = () => {
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-auto relative">
                        <button
                            className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
                            onClick={closePopup}
                        >
                            <FontAwesomeIcon className="w-6 h-6" icon={faXmark} />
                        </button>
                        <div className="flex items-center justify-center mb-4">
                            <img
                                src={popup}
                                alt="Popup Image"
                                className="w-[300px] max-w-full"
                            />
                        </div>
                        <h2 className="text-xl font-semibold mb-2 text-center">
                            Under Development and Upgradation
                        </h2>
                        <p className="text-gray-700 text-center">
                            Our learning website is currently under development and upgradation. Please check back soon for updates!
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
