import React from 'react';
import job from './img/job.png';
import jobchalenge from './img/jobchalenge.png';
import paidintern from './img/paidintern.png';
import remoteintern from './img/remoteintern.png';


const Jobs = [
    {
        id: 1,
        img: job,

    },
    {
        id: 2,
        img: jobchalenge,

    },
    {
        id: 3,
        img: paidintern,

    },
    {
        id: 4,
        img: remoteintern,

    },

]

export default Jobs;