/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Headline from '../../Otherscomponent/Headline';
import interviewObject from '../../Otherscomponent/interviewObject';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Interview = () => {
    const navigate = useNavigate();

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 10
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3    
        }
    };

    const handleChange = (name, img) => {
        navigate(`/interview/${name}`, { state: { img } });
    };

    return (
        <div className='container bg-white py-6 rounded mt-16'>
            <Headline parent='Interview' child='Guide' short='Company' showSeemore={true} to='/interview' />
            <div className="mt-4">
                <Carousel responsive={responsive} className='mt-4 ' containerClass="carousel-container">
                    {interviewObject.map((tutorial) => (
                        <div
                            key={tutorial.id}
                            onClick={() => handleChange(tutorial.title, tutorial.img)}
                            className='shadow-sm rounded me-3 transition ease-in-out delay-50   cursor-pointer'
                        >
                            <div className='w-full h-36 rounded bg-slate-100 hover:bg-slate-200 flex flex-col items-center justify-center'>
                                <img className='w-[70px] h-[70px]' src={tutorial.img} alt={tutorial.title} />
                                <div className='text-black mt-1 font-verdina text-sm text-center'>
                                    {tutorial.title}
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Interview;
